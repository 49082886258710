import type { BasePageContentAPI } from '@backmarket/http-api/src/api-specs-content/models/base-page-content'
import { BLOCKS } from '@contentful/rich-text-types'

const HOME_FALLBACK_DATA: BasePageContentAPI = {
  id: '3WflFt0AhP0k5agSAfqagd',
  name: 'home-duplicated',
  blocks: [
    {
      id: '4AJvHUDFiXd5T7HUFcSnIQ',
      type: 'Carousel',
      props: {
        technicalName: 'Carousel - FINLANDE',
        slides: [
          {
            id: '2rLOaI1ITM8ebHyjlNFOfy',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/5XaH8d0wXchvHkCXksPyA2/b96ac5ea91e626bb63e35b4280c619c3/HP_Desktop___Finnish__1_.jpg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/7GHxpgnmREynNplbKhOasZ/f65a8ceac6798b7e2094aa37e175f542/HP_Mobile___Finnish__1_.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'FI - Good Deals ',
            },
            link: {
              href: 'https://www.backmarket.fi/fi-fi/l/tarjoukset/be9b14f4-89e2-4315-8d3b-4d7d613b562f',
            },
          },
          {
            id: '3WLdytZetCCjaBqD9jZa6n',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/20hbpI1Gmqa5ZbFIWnvf4d/05c407f228f6ca39c62cd441a20013bd/Copy_of_VISION_Desktop_FI.jpg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/6XM4l2ODWIhqZq1IxYzMRw/158c739b084caf9b347c58fcfe2e3ef9/Copy_of_VISION_Mobile_FI.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'FI - Vision',
            },
            link: {
              href: 'https://www.backmarket.fi/fi-fi/about-us',
            },
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '5rZbW3iSSYnjem5AFesUDs',
      type: 'Recommendations',
      props: {
        technicalName: '[FI][WEB] Product reco x Best sellers',
        title: 'Fast & furious!',
        subtitle: 'Parhaat diilimme!',
        widgetId: '5f60e69cf87551a853bc094a',
        ctaLink: {
          link: {
            href: 'https://www.backmarket.fi/fi-fi/l/tarjoukset-kunnostettu/be9b14f4-89e2-4315-8d3b-4d7d613b562f',
          },
          label: 'Kaikki parhaat tarjouksemme',
        },
      },
      hiddenDevices: [],
    },
    {
      id: '1QLevjVM5jXN35k2up6HOF',
      type: 'Recommendations',
      props: {
        technicalName: '[FI][WEB] Product x last product viewed',
        title: 'Äskettäin katsottu',
        subtitle: 'Suosittelemiamme tuotteita',
        widgetId: '64f1f342943d46608414bce1',
      },
      hiddenDevices: [],
    },
    {
      id: '4FK3byIWxHdl19nHjFcZUb',
      type: 'Categories',
      props: {
        title: 'Raskaansarjan kategoriat',
        subtitle: 'Googlattu useammin kuin Muhammad Alia.',
        categories: [
          {
            id: '38gXIFGHasXkBfxyEJnqSG',
            title: 'iPhone',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/6G8NfbciL3rtpnVeXho8BD/c48f3572c475d8b495e76db29fb46263/290045_d7327841-d981-4566-b4a5-a392912a824f-thumb.jpg',
              width: 400,
              height: 400,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/5opbjQ8sRrg3OeN3HI3kI3/1ebd2cd675091486575067a901fe3672/290045_d7327841-d981-4566-b4a5-a392912a824f-thumb__1_.jpg',
              widthMobile: 200,
              heightMobile: 200,
              alt: 'iPhone 11 Pro',
            },
            tagline: 'Markkinoiden vihreimmät omenat.',
            link: {
              href: 'https://www.backmarket.fi/fi-fi/l/iphone-kunnostettu/aabc736a-cb66-4ac0-a3b7-0f449781ed39',
            },
          },
          {
            id: '5Zna8c5Nrrv3v4sM8S20Oz',
            title: 'Airpods ja kuulokkeet',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/6UBdFdF7X6S0nGCexTB9pw/fca072145b46703b672773dfddb49987/308532_7b427385-34fc-40f5-9e68-73890ed77684-thumb.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'AirPods & Earphones',
            },
            tagline: 'Oletko valmis ottamaan podit vastaan?',
            link: {
              href: 'https://www.backmarket.fi/fi-fi/l/airpods-kunnostettu/5091ca6b-c36c-4d50-b644-fb3c5bdd5f16',
            },
          },
          {
            id: '2Cmr0tI1QxWNV7MY8wmHem',
            title: 'MacBook',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/6MZVE5kh4OulMTCQlbTuT8/e15bafa5e909e9a353f4ab3c2d438fa6/MacBook_Pro_Touchbar.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'MacBook Pro TouchBar',
            },
            tagline:
              'Näytä fiksummalta siemailessasi mochaccinoa. Tai tehdessäsi töitä.',
            link: {
              href: 'https://www.backmarket.fi/fi-fi/l/kaikkki-macbook-qwerty-espanja-kunnostettu/50677fe5-b7d2-4f31-b8ed-824cb3bfd345',
            },
          },
          {
            id: '2kmDAMalnGAd0uPVfSfyTL',
            title: 'iPad',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/3q7nQi6Q5LKO60xX5RPieH/b34cdb257c55d6d7ab4fdb4552d538ea/ipad_pro_2019.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'iPad Pro',
            },
            tagline: 'Jopa 40 % alennusta. Ja rahaa jää myös Netflixiin.',
            link: {
              href: 'https://www.backmarket.fi/fi-fi/l/ipad-kunnostettu/6053d9e8-2eaa-4971-9b6e-79b8a16e4dee',
            },
          },
          {
            id: '7a6JSJrGTAMruzRi6g56oB',
            title: 'Pelikonsolit',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/4RrlDaeko9fXSPWbRjV9yW/bb24326d693cab1a8bf87d04860e4f81/EARTHSHOP-PS42-CATEGORYBLOCK.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Consoles & Gaming',
            },
            tagline: 'Game Boysta aina Xbox Oneen.',
            link: {
              href: 'https://www.backmarket.fi/fi-fi/l/pelikonsolit-kunnostettu/2abcb67f-ffa2-4995-b473-167490d42389',
            },
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '7tJSPxsuSv7P9Q3DrJ8y6t',
      type: 'PressCarousel',
      props: {
        technicalName: 'FI - Press Carousel',
        subtitle: '(Ja kertovat kivoja juttuja) ;)',
        title: 'Ihmiset puhuvat meistä!',
        articles: [
          {
            id: '4EAesxoiGoUP06PyLe9wZI',
            text: 'Kunnostetuista kännyköistä tulossa kilpailtu bisnes',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/eeGkfiVPZCQVpxQxmqV1P/dcca9a8fe9d67121401b5a30732339e8/download.png',
              width: 225,
              height: 225,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/eeGkfiVPZCQVpxQxmqV1P/dcca9a8fe9d67121401b5a30732339e8/download.png',
              widthMobile: 225,
              heightMobile: 225,
              alt: 'uusiteknologia logo',
            },
            link: {
              href: 'https://www.uusiteknologia.fi/2021/05/28/kunnostetuista-kannykoista-tulossa-kilpailtu-bisnes/',
            },
            publicationDate: '05/2021',
          },
          {
            id: '1ZJ0lE4u8A8s4nrRM3ZqnP',
            text: 'Suomeen tuli uusi käytettyjen puhelimien kauppa – tällainen on elektroniikkaa myyvä Back Market',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/1UXPc8ca4QpQinKk1kyMsH/29fcfdc96ff2ae630e1a2b79c38b9824/Ilta-sanomat-logo-red.png',
              width: 1000,
              height: 225,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/1UXPc8ca4QpQinKk1kyMsH/29fcfdc96ff2ae630e1a2b79c38b9824/Ilta-sanomat-logo-red.png',
              widthMobile: 1000,
              heightMobile: 225,
              alt: 'ilta sanomat logo',
            },
            link: {
              href: 'https://www.is.fi/digitoday/mobiili/art-2000007639103.html',
            },
            publicationDate: '11/2020',
          },
          {
            id: '2vtQB54Y5cB7AISTpqAR8p',
            text: 'Suomeen rantautunut Back Market haastaa kunnostettujen ja käytettyjen elektroniikkalaitteiden markkinat',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/572pJGEIBqFsmnW5ks8Ilk/2b33f60f02e062d5ad18845f1af5aec2/Afterdawn_logo.png',
              width: 874,
              height: 289,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/572pJGEIBqFsmnW5ks8Ilk/2b33f60f02e062d5ad18845f1af5aec2/Afterdawn_logo.png',
              widthMobile: 874,
              heightMobile: 289,
              alt: 'afterdown.com logo',
            },
            link: {
              href: 'https://fin.afterdawn.com/uutiset/artikkeli.cfm/2020/11/24/back-market-suomi-kunnostettu-elektroniikka',
            },
            publicationDate: '11/2020',
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: 'UQn3xCPYoguO5pUDOsCSi',
      type: 'Faq',
      props: {
        title: 'Mitä muuta?',
        subtitle: 'Neljä kysymystä, joita meiltä aina kysytään',
        questions: [
          {
            id: 'MZ4HrlQOzxkS78drAMimI',
            title: 'Mistä tiedän, ettei laitteeni hajoa kuukauden kuluttua? ',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Teemme kaikkemme varmistaaksemme, että sinulle tarjoamamme laitteet vastaavat lupauksiamme. ',
                      marks: [],
                      data: {},
                    },
                    {
                      nodeType: 'text',
                      value:
                        'Näihin toimiin sisältyvät niin myyjien ilmoitusta edeltävät seulontaprosessit kuin Back Marketin teknisen henkilökunnan tekemät haamutilaukset (jotka tehdään tietysti salaisella koodinimellä).',
                      marks: [],
                      data: {},
                    },
                    {
                      nodeType: 'text',
                      value:
                        ' Lisäksi saat jokaiselle laitteelle 30 päivää aikaa muuttaa mieltäsi ja yhden vuoden takuun.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '5zUoju0VXYAwoJfNQkIotF',
            title: 'Mikä ero on kunnostetulla ja uudella? ',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Ulkopuolelta kunnostettu älypuhelin näyttää samalta kuin uusi (ja toimii samoin). Eniten merkitystä on kuitenkin sisäpuolella. Kunnostetut laitteet, kuten älypuhelimet, tabletit, kannettavat tietokoneet ja pöytäkoneet, kuormittavat ympäristöä paljon vähemmän kuin täysin uudet.\n',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            id: 'VGvF34GKt02lPQtTpdkFK',
            title: 'Hetkinen… mitä muuta te myytte?',
            text: {
              data: {},
              content: [
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [],
                      value:
                        'Voit helpottaa planeettamme oloa monellakin tavalla, ja mitä tulee tekniikkaan, tarjoamme paljon muutakin kuin söpöjä älypuhelimia. Valikoimastamme löytyy lähes kaikkea, aina pelijärjestelmistä kihartimiin tai jopa itkuhälyttimiin ja espressokoneisiin (huomio, uudet vanhemmat).',
                      nodeType: 'text',
                    },
                  ],
                  nodeType: BLOCKS.PARAGRAPH,
                },
              ],
              nodeType: BLOCKS.DOCUMENT,
            },
          },
          {
            id: '1g9QLCZzg8hKREnDQja1Pk',
            title:
              'Oliko T-800 elokuvassa Terminator 2: Tuomion päivä (1991) teknisesti "kunnostettu"?',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Meidän pitäisi ajaa Skynet-sertifiointiprosessimme läpi ensin, mutta olen melko varma, että kyllä, kyseessä on "I’ll be back" -tyyppinen tilanne.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
        ],
        rendering: 'HTML_ONLY',
        ctaLink: {
          link: {
            href: 'https://www.backmarket.fi/fi-fi/help',
          },
          label: 'Löydä vastauksia',
        },
      },
      hiddenDevices: [],
    },
  ],
  type: 'core',
  title: 'Home',
  meta: {
    title: 'Kunnostettujen laitteiden (Super) Marketti',
    description:
      'Löydät parhaat tarjoukset kunnostetuista puhelimista sekä muista laitteista Back Marketissa. Jopa 70% halvemmat hinnat uusiin verrattuna. Toimitus 0€, 12 kk takuu, 30 päivän palautusoikeus.',
    image: {
      src: 'https://images.ctfassets.net/mmeshd7gafk1/5BDjAiB7WVkFJcE5MXLNy4/21a66c53bd1b077a21fa04e5e62b2fb5/SEO-Website-Thumbnail.png',
      width: 516,
      height: 258,
      srcMobile: '',
      widthMobile: 0,
      heightMobile: 0,
      alt: '',
    },
    alternateLinks: [
      {
        country: 'FR',
        locale: 'fr-FR',
      },
      {
        country: 'DE',
        locale: 'de-DE',
      },
      {
        country: 'BE',
        locale: 'fr-BE',
      },
      {
        country: 'ES',
        locale: 'es-ES',
      },
      {
        country: 'IT',
        locale: 'it-IT',
      },
      {
        country: 'GB',
        locale: 'en-GB',
      },
      {
        country: 'AT',
        locale: 'de-AT',
      },
      {
        country: 'NL',
        locale: 'nl-NL',
      },
      {
        country: 'US',
        locale: 'en-US',
      },
      {
        country: 'FI',
        locale: 'fi-FI',
      },
      {
        country: 'PT',
        locale: 'pt-PT',
      },
      {
        country: 'IE',
        locale: 'en-IE',
      },
      {
        country: 'GR',
        locale: 'el-GR',
      },
      {
        country: 'SK',
        locale: 'sk-SK',
      },
      {
        country: 'SE',
        locale: 'sv-SE',
      },
      {
        country: 'JP',
        locale: 'ja-JP',
      },
      {
        country: 'AU',
        locale: 'en-AU',
      },
    ],
  },
  badge: '',
  titleSeo: 'Kunnostettujen laitteiden (Super) Marketti',
  titleBreadcrumb: 'Home',
  banner: {},
  textSeo: null,
  hideFromSearchEngine: false,
  parentPageInfos: null,
  pageType: 'core',
  pageName: 'home-duplicated',
}

export default HOME_FALLBACK_DATA
